<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <informacoes-basicas
          id="informacoes-basicas-rel"
          :baseDados.sync="baseDados"
          :nomeRelatorio.sync="nomeRelatorio"
          :tipoDadoGeografico.sync="tipoDadoGeografico"
        />
      </v-col>
    </v-row>
    <v-row
      :class="esmaecerPagina"
      id="row-campos"
    >
      <v-col cols="12">
        <campos-linhas
          ref="refCamposLinhas"
          id="campos-linhas"
          :campos="camposLinhas"
          :relatorio="relatorio"
          :selecionarTodos="false"
        />
      </v-col>
    </v-row>
    <v-row
      :class="esmaecerPagina"
      id="row-filtros"
    >
      <v-col cols="12">
        <campos-filtros
          ref="refCamposFiltros"
          id="campos-filtros"
          :campos="campos"
          :relatorio="relatorio"
          :baseDados="baseDados"
          @loading="loading = $event"
        />
      </v-col>
    </v-row>
    <v-row
      :class="esmaecerPagina"
      id="row-btn-save"
    >
      <v-col cols="12">
        <v-btn
          id="btn-save-relatorio"
          color="primary"
          min-width="100"
          style="float: right"
          class="mt-n5 mr-0"
          @click="salvar()"
          :loading="loading"
        >
          Salvar
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BasesDadosService from '@/services/BasesDadosService';
import RelatoriosService from '@/services/RelatoriosService';
import camposFiltrosMixins from '@/mixins/camposFiltrosMixins';
import tourMixins from '@/mixins/tourMixins';
import routes from '@/store/modules/routes';

export default {
  mixins: [camposFiltrosMixins, tourMixins],

  components: {
    InformacoesBasicas: () =>
      import('@/components/layers-personalizados/InformacoesBasicas'),
    CamposLinhas: () => import('@/components/relatorios/CamposLinhas'),
    CamposFiltros: () => import('@/components/relatorios/CamposFiltros')
  },

  data: () => ({
    relatorio: {},
    nomeRelatorio: '',
    baseDados: '',
    tipoDadoGeografico: 'PONTO',
    campos: [],
    camposLinhas: [],
    camposValores: [],
    loading: false,
    arrTour: [
      {
        element: '#informacoes-basicas-rel',
        intro:
          'Aqui você deverá escolher um nome para seu layer e selecionar a base de dados desejada.',
        scrollTo: 'tooltip',
        position: 'right'
      },
      {
        element: '#campos-linhas',
        intro:
          'Aqui você deverá selecionar os campos da base que irão compor o layer e serão exibidos nas popups do mapa.',
        scrollTo: 'tooltip',
        position: 'right'
      },
      {
        element: '#campos-filtros',
        intro:
          'Aqui você deverá adicionar filtros para processamento de seu layer.',
        scrollTo: 'tooltip',
        position: 'top'
      },
      {
        element: '#btn-add-remove-filtro',
        intro: 'Aqui você poderá adicionar ou remover filtros de seu layer.',
        scrollTo: 'tooltip',
        position: 'top'
      }
    ]
  }),

  computed: {
    esmaecerPagina() {
      return this.baseDados == '' ? 'esmaecer esmaecer-aux' : '';
    }
  },

  mounted() {
    if (this.$route.params.id) this.getRelatorio(this.$route.params.id);

    this.iniciarTourPagina();
  },

  methods: {
    getRelatorio(id) {
      RelatoriosService.getRelatorio(id)
        .then((response) => {
          const rData = response.data.data;
          this.relatorio = rData;
          this.nomeRelatorio = rData.nome;
          this.baseDados = JSON.parse(rData.estrutura_json).tabela;
          this.tipoDadoGeografico = this.relatorio.tipo_dado_geografico;
        })
        .catch(() =>
          this.$toast.error('Erro ao recuperar o layer.', '', {
            position: 'topRight'
          })
        );
    },

    baseDeDadosUpdated(tabela) {
      BasesDadosService.getBasesDadosCampos(tabela)
        .then((campos) => {
          const ignorarCampos = this.getIgnorarCamposFiltros();
          this.campos = campos.filter(
            (campo) => !ignorarCampos.includes(campo.column)
          );
          this.camposLinhas = this.campos;
        })
        .catch(() => {
          this.$toast.error(
            'Erro ao recuperar campos da base de dados selecionada.',
            '',
            { position: 'topRight' }
          );
        });
    },

    salvar() {
      let data = {
        id: this.$route.params.id ? this.$route.params.id : null,
        nome: this.nomeRelatorio,
        base_dados: this.baseDados,
        campos: this.$refs.refCamposLinhas.exportCampos(),
        valores: [],
        filtros: this.$refs.refCamposFiltros.exportCampos(),
        dados_geograficos: true,
        tipo_dado_geografico: this.tipoDadoGeografico
      };

      data = this.validaCamposBeforeSave(data);

      if (data !== 'invalido') {
        RelatoriosService.save(data)
          .then(() => {
            this.$toast.success('Layer salvo com sucesso.', '', {
              position: 'topRight'
            });
            this.$router.replace({
              name: routes.state.appRoutes['LAYERS_PERSONALIZADOS_HISTORICO']
            });
          })
          .catch(() => {
            this.$toast.error('Erro ao salvar o layer.', '', {
              position: 'topRight'
            });
          })
          .finally(() => (this.loading = false));
      }
    },

    validaCamposBeforeSave(data) {
      if (data.filtros == 'invalido') {
        return 'invalido';
      }
      this.loading = true;

      if (data.base_dados == '' || data.nome == '') {
        this.$toast.warning('Preencha as informações básicas.', '', {
          position: 'topRight'
        });
        this.loading = false;
        return 'invalido';
      }

      if (data.campos.length == 0 && data.valores.length == 0) {
        this.$toast.warning('Selecione pelo menos um campo.', '', {
          position: 'topRight'
        });
        this.loading = false;
        return 'invalido';
      }

      return data;
    },

    iniciarTourPagina() {
      if (this.tourFuncCalled) return;

      let onBeforeChangeFunction = {
        func: () => {
          let elementsEsmaecidos = document.querySelectorAll(
            '#row-campos, #row-filtros, #row-btn-save'
          );
          elementsEsmaecidos.forEach((element) => {
            element.classList.remove('esmaecer');
          });
        },
        runInStep: 1
      };

      let onBeforeExitFunction = {
        func: () => {
          let elementsEsmaecidos = document.querySelectorAll(
            '#row-campos, #row-filtros, #row-btn-save'
          );
          elementsEsmaecidos.forEach((element) => {
            element.classList.add('esmaecer');
          });
        }
      };

      this.iniciarTour(
        this.arrTour,
        [],
        [onBeforeChangeFunction],
        [onBeforeExitFunction]
      );
    }
  },

  watch: {
    baseDados(newValue) {
      this.baseDeDadosUpdated(newValue);
    }
  }
};
</script>

<style scoped>
.esmaecer {
  pointer-events: none;
  opacity: 0.4;
}
</style>
